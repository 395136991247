import React from 'react';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography';
import { lng } from '../../utils/language';
import { useSelector } from 'react-redux';
import { Alert, CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Countries from '../../pages/public/Countries.json';
import FetchData from '../../api/FetchData';
import { trackCustomEvent } from '../../utils/facebookPixel';

const inputStyle = {
    backgroundColor: '#FFF',
    '.MuiInputBase-root': {
        borderColor: '#FFF',
    },
    fieldset: {
        borderRadius: 0,
        borderWidth: '2px',
        borderColor: '#FFF'
    }
}

const defaultUserInfo = {
    fullName: '',
    lastname: '',
    email: '',
    mob: '',
    prefix: '',
    country: '',
}

const defaultUserInfoError = {
    fullNameError: false,
    lastNameError: false,
    emailError: false,
    prefixError: false,
    mobError: false,
    countryError: false
}

const defaultAlert = {
    open: false,
    severity: '',
    msg: ''
}

export default function Footer() {

    const unicodeToEmoji = (unicode) => {
        return JSON.parse(`"${unicode}"`);
      };

    const [loadingButton, setLoadingButton] = React.useState(false);
    const language = useSelector((state) => state.language.language);
    const [alert, setAlert] = React.useState(defaultAlert);
    const [userInfo, setUserInfo] = React.useState(defaultUserInfo)
    const [userInfoErrors, setUserInfoErrors] = React.useState(defaultUserInfoError)
    const inputFunc = {
        onChangeName: (e) => {
            var value = e.target.value;
            if (value !== "" && !value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g)) {
                setUserInfoErrors({ ...userInfoErrors, fullNameError: true })
            } else if (value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g) || value !== "") {
                setUserInfoErrors({ ...userInfoErrors, fullNameError: false })
            }
            setUserInfo({ ...userInfo, fullName: value })
        },
        onChangeLastname: (e) => {
            var value = e.target.value;
            if (value !== "" && !value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g)) {
                setUserInfoErrors({ ...userInfoErrors, lastNameError: true })
            } else if (value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g) || value !== "") {
                setUserInfoErrors({ ...userInfoErrors, lastNameError: false })
            }
            setUserInfo({ ...userInfo, lastname: value })
        },
        onChangeCountry: (e) => {
            // var value = e.target.value;
            // if (value !== "" && !value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g)) {
            //     setUserInfoErrors({ ...userInfoErrors, countryError: true })
            // } else if (value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g) || value !== "") {
            //     setUserInfoErrors({ ...userInfoErrors, countryError: false })
            // }
            // setUserInfo({ ...userInfo, country: value })
            var value = e.target.value; if (value !== "") { setUserInfoErrors({ ...userInfoErrors, countryError: false }) } setUserInfo({ ...userInfo, country: value });
        },
        onChangeEmail: (e) => {
            var value = e.target.value;
            if (value !== "" && !value.match(/^[\w\-.]+@([\w-]+\.)+[\w-]{2,}/)) {
                setUserInfoErrors({ ...userInfoErrors, emailError: true })
            } else if (value.match(/^[\w\-.]+@([\w-]+\.)+[\w-]{2,}/) || value === "") {
                setUserInfoErrors({ ...userInfoErrors, emailError: false })
            }
            setUserInfo({ ...userInfo, email: value })
        },
        onChangeCountryCode: (e) => {
            var value = e.target.value;
            const regex = /^(\+?\d{1,3}|\d{1,4})$/;
            if (value !== "" && !value.match(regex)) {
                setUserInfoErrors({ ...userInfoErrors, countryCodeError: true })
            } else if (value.match(regex) || value !== "") {
                setUserInfoErrors({ ...userInfoErrors, countryCodeError: false })
            }
            setUserInfo({ ...userInfo, countryCode: value })

        },
        onChangePrefix: (e) => {
                var value = e.target.value;
                if (value === '') {
                    setUserInfoErrors({ ...userInfoErrors, prefixError: true })
                }
                setUserInfo({ ...userInfo, prefix: value })
        },
        onChangeTel: (e) => {
            var value = e.target.value;
            const regex = /^[0-9\s]*$/;
            if (value !== "" && !value.match(regex)) {
                setUserInfoErrors({ ...userInfoErrors, mobError: true })
            } else if (value.match(regex) || value !== "") {
                setUserInfoErrors({ ...userInfoErrors, mobError: false })
            }
            setUserInfo({ ...userInfo, mob: value })
        },
    }
    const hasEmptyFields = () => {
        return Object.values(userInfo).some(value => value === '');
    };

    const handleContact = (e) => {
        e.preventDefault();
        const validation = Object.values(userInfoErrors);
        const confirmation = validation.includes(true);
        if (!confirmation && hasEmptyFields() === false) {
            setLoadingButton(true);
            FetchData.create('/email/contactUs', {
                user: userInfo,
                lang: language
            })
            .then(res => {
                if (res.data.success) {
                    trackCustomEvent("Contact", {
                        type: 'Contact Us',
                        Date: new Date(),
                        firstname: userInfo.fullName,
                        lastname: userInfo.lastname,
                        email: userInfo.email,
                        tel: userInfo.mob,
                        prefix: userInfo.prefix,
                        country: userInfo.country,
                    });
                    setAlert({
                        open: true,
                        msg: res.data.msg,
                        severity: res.data.severity
                    })
                }
                setUserInfo(defaultUserInfo);
                setUserInfoErrors(defaultUserInfoError);
                setLoadingButton(false);
                handleClose();
            })
            .catch(err => {
            })
        }else{
            setLoadingButton(false);
        }
    }

    const handleClose = (event, reason) => {
        setTimeout(() => {
            setAlert({ ...alert, open: false });
        }, 3000)
    };

    return (
        <>
            <Box sx={{ marginTop: '50px' }}>
                <Box sx={{ height: '300px' }}>
                    <Box component={'img'} src="https://fundway.com/images/2024/Footer.webp" sx={{ height: '100%', width: '100%', objectFit: 'cover' }} />
                </Box>

                <Box sx={{
                    backgroundColor: '#002856',
                    paddingY: '20px',

                }}>
                    <Box sx={{ paddingY: '20px' }}>
                        <Typography variant="body2" color={'#FFF'} textAlign={'center'} fontWeight={800} gutterBottom sx={{ fontSize: '11px' }}>
                            {lng[language]['makeVloraMarina'].toUpperCase()}
                        </Typography>
                        <Typography variant="h6" color={'#FFF'} textAlign={'center'} sx={{ fontSize: '25px' }}>
                            {lng[language]['contactUs']}
                        </Typography>
                    </Box>

                    <Box sx={{ display: 'grid', gridTemplateRows: 'auto auto auto auto auto', rowGap: '20px', width: { sm: '300px', xs: '90%' }, marginX: 'auto', paddingBottom: '20px' }}>
                        <Box>
                            <InputLabel sx={{ fontWeight: '600', marginBottom: '10px', color: '#FFF' }}>{lng[language]['name']}</InputLabel>
                            <TextField
                                fullWidth
                                sx={inputStyle}
                                value={userInfo.fullName}
                                error={userInfoErrors.fullNameError}
                                onChange={(e) => inputFunc.onChangeName(e)}
                            />
                        </Box>

                        <Box>
                            <InputLabel sx={{ fontWeight: '600', marginBottom: '10px', color: '#FFF' }}>{lng[language]['lastName']}</InputLabel>
                            <TextField
                                fullWidth
                                sx={inputStyle}
                                value={userInfo.lastname}
                                error={userInfoErrors.lastNameError}
                                onChange={(e) => inputFunc.onChangeLastname(e)}
                            />
                        </Box>


                        <Box>
                            <InputLabel sx={{ fontWeight: '600', marginBottom: '10px', color: '#FFF' }}>{lng[language]['countty2']}</InputLabel>
                            <FormControl sx={{ '.MuiInputBase-root': { borderRadius: 0 }, width: '100%', backgroundColor: '#FFF' }}>
                                <Select onChange={(e) => inputFunc.onChangeCountry(e)} error={userInfoErrors.countryError} value={userInfo.country}>
                                    {
                                        Countries.map((data, index) => (
                                            <MenuItem key={index} value={data?.countryId}>{data?.country} {unicodeToEmoji(data.flag)}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Box>

                        <Box>
                            <InputLabel sx={{ fontWeight: '600', marginBottom: '10px', color: '#FFF' }}>{lng[language]['email']}</InputLabel>
                            <TextField
                                fullWidth
                                sx={inputStyle}
                                value={userInfo.email}
                                error={userInfoErrors.emailError}
                                onChange={(e) => inputFunc.onChangeEmail(e)}
                            />
                        </Box>

                        <Box>
                            <InputLabel sx={{ fontWeight: '600', marginBottom: '10px', color: '#FFF' }}>{lng[language]['phone']}</InputLabel>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <FormControl sx={{ '.MuiInputBase-root': { borderRadius: 0 }, width: '120px', '.MuiSelect-select': { paddingY: '16.5px !important', paddingX: '5px' }, background: '#FFF' }}>
                                    <Select onChange={(e) => inputFunc.onChangePrefix(e)} value={userInfo.prefix ?? ''}>
                                        {
                                            Countries.map((data, index) => (
                                                <MenuItem key={index} value={data.phone_code}>{data.phone_code} {unicodeToEmoji(data.flag)}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                <TextField
                                    type='tel'
                                    fullWidth
                                    sx={[{ marginLeft: '10px' }, inputStyle]}
                                    value={userInfo.mob}
                                    error={userInfoErrors.mobError}
                                    onChange={(e) => inputFunc.onChangeTel(e)}
                                />
                            </Box>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button disabled={loadingButton ? true : false} onClick={(e) => handleContact(e)} sx={{ backgroundColor: loadingButton ? '#fff !important' : '#fff', width: '120px', paddingY: 1, color: '#002856', borderStyle: 'solid', borderWidth: '1px', borderColor: '#002856', borderRadius: 0, '&:hover': { color: '#FFF', borderColor: '#FFF' } }}>
                                {
                                    loadingButton ?
                                    <CircularProgress sx={{width: '25px !important', height: '25px !important', color: '#002856'}} />
                                    :
                                    lng[language]['sendBtn']
                                }
                            </Button>
                        </Box>
                    </Box>

                </Box>
            </Box>
            <Box
                sx={{ width: { md: '1024px', sm: '80%', xs: '90%' }, marginTop: 3, marginX: 'auto', display: 'grid', paddingY: '80px', justifyItems: { sm: 'stretch', xs: 'center' }, gridTemplateColumns: { sm: 'repeat(2, 1fr)', xs: '1fr' }, rowGap: { sm: 'unset', xs: '50px' } }}>

                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                    }}
                >
                    {/* LOGO */}
                    <Box sx={{ marginRight: '30px' }}>
                        <Box component={'img'} src='https://fundway.com/images/logo.svg' height={'30px'} />
                    </Box>
                    {/* SHARE SOCIAL MEDIA */}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100px' }}>
                        <Box component={Link} to={'https://www.facebook.com/people/Fundway/61558436587870/'} target='_blank'>
                            <Box component={'img'} src="https://fundway.com/images/icons/facebook.svg" sx={{ width: '25px', height: ' 25px' }} />
                        </Box>

                        <Box component={Link} to={'https://www.instagram.com/fundway.properties/'} target='_blank'>
                            <Box component={'img'} src="https://fundway.com/images/icons/instagram.svg" sx={{ width: '25px', height: ' 25px' }} />
                        </Box>

                        <Box component={Link} to={'https://www.linkedin.com/company/fundway-properties?trk=public_post_feed-actor-name'} target='_blank'>
                            <Box component={'img'} src="https://fundway.com/images/icons/linkedin.svg" sx={{ width: '25px', height: ' 25px' }} />
                        </Box>
                    </Box>
                </Box>

                <Box>
                    {/* EMAIL */}
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>

                        <TextField
                            sx={{ marginRight: '30px' }}
                            id=""
                            variant='standard'
                            label="Enter Your Email"
                        // value={}
                        // onChange={}
                        />

                        <Button variant='contined' sx={{ color: '#FFF', borderRadius: 0, boxShadow: 'none', background: '#002856', paddingX: '20px', paddingY: '10px', border: '1px solid #a2a2a2', '&:hover': { color: '#002856', background: '#FFF', border: '1px solid #a2a2a2' } }}>
                            SUBSCRIBE
                        </Button>

                    </Box>
                </Box>

            </Box>

            <Box sx={{
                width: {
                    md: '1024px',
                    xs: '90%'
                },
                marginX: 'auto',
                paddingBottom: '50px'
            }}>
                <Box
                    component={'iframe'}
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d259.3455112510959!2d21.1566990928531!3d42.657976263572166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13549ffb2ec89a13%3A0xa0817bad56862f98!2sFundway!5e0!3m2!1sen!2s!4v1724415649727!5m2!1sen!2s"
                    width="100%"
                    sx={{
                        border: 'none',
                        height: '500px',
                        width: '100%'
                    }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Fundway Map"
                ></Box>
            </Box>
            {
                alert.open &&
                <Box sx={{ width: '100%', bottom: '0', display: 'flex', position: 'fixed', alignItems: 'center', justifyContent: 'center' }}>
                    <Alert onClose={handleClose} severity={alert.severity} variant="filled" sx={{'.MuiAlert-action': {display: 'none'}, display: 'flex', justifyContent: 'center', backgroundColor: '#002856', width: '100%'}}>
                        {lng[language][alert.msg]}
                    </Alert>
                </Box>
            }
        </>
    )
}