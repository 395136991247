import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
// import { useNavigate } from 'react-router-dom';
import FetchData from '../../api/FetchData';
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { useSelector } from 'react-redux';
import { lng } from '../../utils/language';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress, TextField } from '@mui/material';
import SkeletonComp from '../../components/SkeletonComp';
import EmailAlert from '../../components/functions/EmailAlert';
import { removeDownPayment } from '../../utils/utils';
import Loader from '../../components/loader/Loader';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const inputStyle = {
    '.MuiInputBase-root': {
        width: { md: '100px', xs: '70px' },
    },
    '.MuiInputBase-input': {
        padding: 0,
        width: { md: '100px', xs: '70px' },
        height: { md: '100px', xs: '70px' },
        fontSize: '30px',
        textAlign: 'center',
    }
}

const defaultAlert = {
    open: false,
    severity: '',
    msg: ''
}

export default function SentEmail() {

    const [loading, setLoading] = useState(true);
    const language = useSelector((state) => state.language.language);
    const navigate = useNavigate();
    const [loadingButton, setLoadingButton] = React.useState(false);
    const [user, setUser] = useState({ email: '' })
    const query = useQuery();
    const userId = query.get('id');
    // const reserveId = query.get('reserveId');

    const [confirmed, setConfirmed] = useState(false);
    const [validTime, setValidTime] = useState(false);
    const [userExsist, setUserExsist] = useState(false);
    const [verifiySuccess, setVerifiySuccess] = useState(false);
    const [alert, setAlert] = useState(defaultAlert)
    const [emailButtonLoading, setEmailButtonLoading] = useState(true);
    const [confirmationSent, setConfirmationSent] = useState(false);

    const obtainUserData = () => {
        FetchData.create('/user/checkuser', {
            usertoken: userId
        })
            .then(res => {
                if (res?.data?.success) {
                    if (res?.data?.doesUserExsist) {
                        setUserExsist(true);
                        setUser({ email: res.data.email });
                    }
                    if (res?.data?.isUserVerified) {
                        setConfirmed(true);
                        setVerifiySuccess(true);
                        removeDownPayment();
                        navigate(-1);
                    }
                    setValidTime(res?.data?.isTimeValid);
                }
                setEmailButtonLoading(false);
                setLoading(false);
            })
            .catch(err => {
                console.log("Error:", err);
            })
    }

    const handleResendCode = () => {
        setLoading(true);
        setLoadingButton(true);
        FetchData.create('/email/resendEmail', {
            userId: userId,
            lang: language
        }
        ).then(res => {
            if (res?.data?.success) {
                if (res?.data?.doesUserExsist) {
                    setUserExsist(true);
                    setUser({ email: res.data.email });
                }
                if (res?.data?.isUserVerified) {
                    setConfirmed(true);
                    setVerifiySuccess(true);
                    navigate(-1);
                }
                if (res.data.isTimeValid) {
                    setValidTime(res?.data?.isTimeValid);
                }
                setAlert({
                    open: true,
                    severity: res?.data?.severity,
                    msg: res?.data?.msg
                });
                setLoadingButton(false);
                setLoading(false)
            }
        })
    }
    useEffect(() => {
        obtainUserData();
        // eslint-disable-next-line
    }, [])

    const [code, setCode] = useState({
        first: '',
        second: '',
        third: '',
        fourth: '',
    })

    const inputRef1 = useRef(null);
    const inputRef2 = useRef(null);
    const inputRef3 = useRef(null);
    const inputRef4 = useRef(null);

    const inputs = {
        firstNum: (e) => {
            let value = e.target.value;
            const regex = /^[0-9\b]+$/;
            if (value === '' || regex.test(value)) {
                setCode({
                    ...code,
                    first: value
                });
                inputRef2.current.focus();
            }
            else return
        },
        secondNum: (e) => {
            let value = e.target.value;
            const regex = /^[0-9\b]+$/;
            if (value === '' || regex.test(value)) {
                setCode({
                    ...code,
                    second: value
                });
                inputRef3.current.focus();
            } else return

        },
        thirdNum: (e) => {
            let value = e.target.value;
            const regex = /^[0-9\b]+$/;
            if (value === '' || regex.test(value)) {
                setCode({
                    ...code,
                    third: value
                });
                inputRef4.current.focus();
            } else return
        },
        fourthNum: (e) => {
            let value = e.target.value;
            const regex = /^[0-9\b]+$/;
            if (value === '' || regex.test(value)) {
                setCode({
                    ...code,
                    fourth: value
                });
                if (value !== '') {
                    if (code.first !== '' && code.second !== '' && code.third !== '') {
                        hanleSubmit(`${code.first}${code.second}${code.third}${value}`)
                    } else return
                }
            } else return
        },
    }


    const hanleSubmit = (code) => {
        setLoading(true);
        setConfirmationSent(true);
        FetchData.create('/email/checkConfirmation', {
            code: code,
            lang: language,
            usertoken: userId
        })
            .then(res => {
                if (res?.data?.success === true) {
                    setConfirmed(true);
                    setVerifiySuccess(true);
                    removeDownPayment();
                    setTimeout(() => {
                        navigate(-1);
                    }, 5000);
                } else {
                    setCode({ first: '', second: '', third: '', fourth: '' })
                }
                setAlert({
                    open: true,
                    severity: res?.data?.severity,
                    msg: res?.data?.msg
                });
                setConfirmationSent(false);
                setLoading(false)
            })
            .catch(err => {
            })
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginY: '100px' }}>
            {
                loading === false ?
                    (userExsist && verifiySuccess === false) ?
                        <>
                            {
                                validTime ?
                                    <Box sx={{ display: 'grid', gridTemplateRows: 'auto auto auto', justifyItems: 'center', rowGap: '20px' }}>

                                        <Box sx={{ width: '100px' }}>
                                            <Box component={'img'} src='https://fundway.com/images/icons/confirmMail.svg' sx={{ width: '100%' }} />
                                        </Box>

                                        <Box sx={{ textAlign: 'center', color: '#002856' }}>
                                            <Typography variant="h6" sx={{ fontSize: '25px', fontWeight: 'bold' }}>{lng[language]['sentEmailHeader']}</Typography>
                                        </Box>

                                        <Box sx={{ width: '90%', textAlign: 'center', color: '#002856' }}>
                                            <Typography variant="body1" sx={{ fontSize: '23px', fontWeight: 'bold' }}>
                                                {lng[language]['sentEmailDesc']}
                                            </Typography>
                                            <Typography variant="body1" sx={{ fontSize: '23px', fontWeight: 'bold', textDecoration: 'underline' }}>
                                                {user.email}
                                            </Typography>
                                        </Box>

                                        <Box>
                                            <Typography variant="body1" sx={{ fontSize: '17px', color: '#002856' }}>
                                                {lng[language]['spamFolder']}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', columnGap: { sm: '50px', xs: '25px' } }}>
                                            {
                                                emailButtonLoading || confirmationSent ?
                                                    <>
                                                        <SkeletonComp
                                                            variant={'rectangular'}
                                                            style={{ border: 'solid 1px #00285647', height: { sm: '120px !important', xs: '75px !important' }, width: { sm: '90px !important', xs: '75px !important' }, borderRadius: '3px' }}
                                                        />

                                                        <SkeletonComp
                                                            variant={'rectangular'}
                                                            style={{ border: 'solid 1px #00285647', height: { sm: '120px !important', xs: '75px !important' }, width: { sm: '90px !important', xs: '75px !important' }, borderRadius: '3px' }}
                                                        />

                                                        <SkeletonComp
                                                            variant={'rectangular'}
                                                            style={{ border: 'solid 1px #00285647', height: { sm: '120px !important', xs: '75px !important' }, width: { sm: '90px !important', xs: '75px !important' }, borderRadius: '3px' }}
                                                        />

                                                        <SkeletonComp
                                                            variant={'rectangular'}
                                                            style={{ border: 'solid 1px #00285647', height: { sm: '120px !important', xs: '75px !important' }, width: { sm: '90px !important', xs: '75px !important' }, borderRadius: '3px' }}
                                                        />
                                                    </>
                                                    :
                                                    confirmed === true ?
                                                        <>
                                                            <TextField sx={inputStyle} value={code.first} inputProps={{ maxLength: 1 }} inputRef={inputRef1} type='tel' disabled={true} />
                                                            <TextField sx={inputStyle} value={code.second} inputProps={{ maxLength: 1 }} type='tel' disabled={true} />
                                                            <TextField sx={inputStyle} value={code.third} inputProps={{ maxLength: 1 }} type='tel' disabled={true} />
                                                            <TextField sx={inputStyle} value={code.fourth} inputProps={{ maxLength: 1 }} type='tel' disabled={true} />
                                                        </>
                                                        :
                                                        <>
                                                            <TextField sx={inputStyle} value={code.first} onChange={(e) => inputs.firstNum(e)} inputProps={{ maxLength: 1 }} autoFocus={true} inputRef={inputRef1} type='tel' disabled={!validTime} />
                                                            <TextField sx={inputStyle} value={code.second} onChange={(e) => inputs.secondNum(e)} inputProps={{ maxLength: 1 }} inputRef={inputRef2} type='tel' disabled={!validTime} />
                                                            <TextField sx={inputStyle} value={code.third} onChange={(e) => inputs.thirdNum(e)} inputProps={{ maxLength: 1 }} inputRef={inputRef3} type='tel' disabled={!validTime} />
                                                            <TextField sx={inputStyle} value={code.fourth} onChange={(e) => inputs.fourthNum(e)} inputProps={{ maxLength: 1 }} inputRef={inputRef4} type='tel' disabled={!validTime} />
                                                        </>
                                            }
                                        </Box>
                                    </Box>
                                    :

                                    <Box sx={{ display: 'grid', gridTemplateRows: 'auto auto', alignItems: 'center', justifyItems: 'center', width: { sm: '100%', xs: '90%' } }}>
                                        <Box sx={{ width: '100px' }}>
                                            <Box component={'img'} src='https://fundway.com/images/icons/emailFail.svg' sx={{ width: '100%' }} />
                                        </Box>
                                        <Typography variant="h6" textAlign={'center'} sx={{ marginTop: '20px', color: '#002856', width: { sm: '500px', xs: '90%' }, fontSize: '25px', fontWeight: 'bold' }}>
                                            {lng[language]['confirmEmailFail']}
                                        </Typography>
                                    </Box>
                            }


                            <Box sx={{ display: 'grid', gridTemplateRows: 'auto auto', justifyItems: 'center', rowGap: '20px', marginTop: '30px' }}>
                                <Box sx={{ width: { md: '384px', xs: '90%' }, textAlign: 'center', color: '#002856' }}>
                                    <Button disabled={loadingButton ? true : false} onClick={() => handleResendCode()} variant="contained" sx={{ width: loadingButton ? '120px' : 'max-content', border: 'solid 1px #707070', borderRadius: 0, color: '#707070', background: loadingButton ? '#707070 !important' : 'transparent', boxShadow: 0, padding: '10px 20px', '&:hover': { backgroundColor: '#707070', color: '#FFF' } }}>
                                        {
                                            loadingButton || confirmationSent ?
                                                <CircularProgress sx={{ width: '25px !important', height: '25px !important', color: '#002856' }} />
                                                :
                                                lng[language]['resendCode'].toUpperCase()
                                        }
                                    </Button>
                                </Box>

                            </Box>
                        </>
                        :
                        <Box sx={{ display: 'grid', gridTemplateRows: 'auto auto', alignItems: 'center', justifyItems: 'center', width: { sm: '100%', xs: '90%' } }}>
                            <Box sx={{ width: '100px' }}>
                                <Box component={'img'} src='https://fundway.com/images/icons/confirmMail.svg' sx={{ width: '100%' }} />
                            </Box>
                            <Typography variant="h6" textAlign={'center'} sx={{ marginTop: '20px', color: '#002856', width: '303px', fontSize: '25px', fontWeight: 'bold' }}>
                                {lng[language]['confirmEmailSuccess']}
                            </Typography>
                            <Typography variant="h6" textAlign={'center'} sx={{ marginTop: '20px', color: '#002856', width: { sm: '500px', xs: '90%' }, fontSize: '25px', fontWeight: 'bold' }}>
                                {lng[language]['specialOfferHasBennSent']}
                            </Typography>
                        </Box>
                    :
                    <Loader state={loading} />
            }

            {
                alert.open &&
                <EmailAlert open={alert.open} severity={alert.severity} setOpen={setAlert} msg={lng[language][alert.msg]} />
            }

        </Box>
    )
}