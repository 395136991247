import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { lng } from '../../utils/language';

export default function VerticalCard({imageUrl, title, desc, buttonUrl}) {

    const language = useSelector((state) => state.language.language)

    return (
        <Box
            sx={{
                width:{md: 'auto', xs: '90%'},
                marginX: 'auto'
            }}
        >

            <Box>
                <Box 
                    component={'img'}
                    src={imageUrl}
                    sx={{
                        objectFit: 'cover',
                        width:'100%',
                        height: {sm: '450px', xs: '400px'},
                        marginX: 'auto'
                    }}
                />
            </Box>

            <Box sx={{display: 'grid', gridTemplateRows: '53px 60px auto'}}>
                <Typography variant="h3" color={'#002856'} sx={{fontSize: '24px', textAlign: 'center', marginY: '10px'}} >{title}</Typography>
                <Typography variant="body1" color={'#53626F'} sx={{fontSize: {md: '15px', xs: '13px'}, textAlign: 'center', marginY: '10px'}}>{desc}</Typography>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <Button LinkComponent={Link} to={buttonUrl} sx={{textAlign: 'center', marginY: '10px', boxShadow: 'none', backgroundColor: '#002856', color: '#FFF', border: 'solid 1px #002856', borderRadius: 0, '&:hover': {backgroundColor: '#FFF', color: '#002856'}}} variant='contained'>
                        {lng[language]['learnMore']}
                    </Button>
                </Box>
            </Box>

        </Box>
    )
}