import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { lng } from '../../utils/language';
import { useSelector } from 'react-redux';

export default function ResizencyCard({ image, title, beds, price, link, soldOut, status, orientation }) {
    const language = useSelector((state) => state.language.language);
    return (
        <Box>
            {image && <Box sx={{height: '200px',backgroundImage: `url("${image}")`,backgroundSize: 'cover',backgroundPosition: 'center',backgroundRepeat: 'no-repeat'}}></Box>}
            <Box sx={{marginTop: '10px',rowGap: '10px',display: 'grid',color: '#002856',gridTemplateRows: { md: '1fr 1fr', sm: '1fr 1fr', xs: '20px 40px' }}}>
                {title && <Typography variant="h1" sx={{ fontSize: '20px', fontWeight: 500 }}>{title}</Typography>}
                <Typography variant="h1" sx={{ fontSize: '15px', fontWeight: 500 }}>{lng[language]['bedsTxt']}: {beds}</Typography>
                {
                    // status === 'free' ?
                    // <Typography variant="h1" sx={{fontSize: '15px', fontWeight: 500}}>{lng[language]['startingFromHome']} {new Intl.NumberFormat('us-US', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format(price)}</Typography>
                    // :
                    <Typography variant="h1" sx={{ fontSize: '15px', fontWeight: 500 }}>{lng[language][orientation]} {lng[language]['orientation']}</Typography>
                }
            </Box>

            <Box>
                <Button
                    LinkComponent={Link}
                    disabled={soldOut}
                    to={!soldOut && `https://fundway.com/residencieDetails/${link}`}
                    sx={{color: soldOut ? '#FFF !important' : '#FFF',borderRadius: 0,boxShadow: 'none',marginTop: '10px',border: 'solid 1px',borderColor: soldOut ? 'gray' : '#002856',backgroundColor: soldOut ? 'gray' : '#002856', '&:hover': {color: '#002856', backgroundColor: '#FFF' }}}>
                    {
                        soldOut ?
                        lng[language]['soldOut']
                        :
                        lng[language]['learnMore']
                    }
                </Button>
            </Box>

        </Box>
    )
}