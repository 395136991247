import { Box, Button } from '@mui/material'
import React from 'react'
import FetchData from '../../api/FetchData'

export default function TestSite() {

    const handleApi = () =>{
        FetchData.create('/user/testApi', {
           
        })
        .then(res => {
        })
    }

    return (
        <Box
            sx={{height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
        >
            <Button onClick={() => handleApi()}>

                CLICK

            </Button>

        </Box>
    )
}
