import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'
import FetchData from '../../api/FetchData';
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import { lng } from '../../utils/language';
import { useSelector } from 'react-redux';
import AvaLoader from './AvailabilityLoader.json';
import SkeletonComp from '../../components/SkeletonComp';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import ModalComp from '../../components/modal/ModalComp';
import CloseIcon from '@mui/icons-material/Close';
import { selectedItem } from '../../utils/utils';

const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            delayChildren: 0.3,
            staggerChildren: 0.2
        }
    }
};

const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1
    }
};

export default function Availability() {

    const stickyRef = useRef(null);
    //eslinst-disable-next-line
    const [
        isSticky, 
        // eslint-disable-next-line
        setIsSticky
    ] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 899;

    const navigate = useNavigate()

    useEffect(() => {
        // const handleScroll = () => {
        //     if (stickyRef.current) {
        //         const stickyTop = stickyRef.current.getBoundingClientRect().top;
        //         // Check if the top of the sticky element has reached the top of the viewport
        //         if (stickyTop <= 0) {
        //             setIsSticky(true);
        //         } else {
        //             setIsSticky(false);
        //         }
        //     }
        // };

        // window.addEventListener('scroll', handleScroll);

        // // Cleanup listener on component unmount
        // return () => {
        //     window.removeEventListener('scroll', handleScroll);
        // };
    }, []);


    const language = useSelector((state) => state.language.language);
    const [dataSet, setDataSet] = useState({
        apartments: null,
        loading: true,
    });
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [data, setData] = useState()
    const [visibleAps, setVisibleAps] = useState();
    const [otherLoader, setOtherLoader] = useState(false);
    useEffect(() => {
        FetchData.create('/apartments/getApartments', {
            orderBy: 'floorNo',
        })
            .then(res => {
                window.scrollTo(0, 0);
                if (res?.data?.success) {
                    setData(res?.data?.data.apartment);
                    setVisibleAps(res?.data?.data.apartment.splice(0, 15));
                    setCount(res?.data?.data.count[0]);
                }
                setDataSet({ ...dataSet, loading: false });
            })
            .catch(err => {
                console.log(err);
            })
        // eslint-disable-next-line
    }, []);

    const loadMoreData = () => {
        setOtherLoader(true);
        const newVisibleCount = parseInt(page) + parseInt(15);
        setPage(newVisibleCount);
        setVisibleAps(prevApartments => [
            ...prevApartments,
            ...data.slice(page, newVisibleCount),
          ]);
        setOtherLoader(false);
    }

    const [image, setImage] = useState('Default');
    const [imageModal, setImageModal] = useState(false);
    const [name, setName] = useState('');
    const [orientation, setOrientation] = useState('');
    const [imageLoading, setImageLoading] = useState(true);
    const [id, setId] = useState();
    const handleImageModal = (appId, name, orientation, id) => {
        setImage(appId);
        setImageModal(true);
        setName(name);
        setOrientation(orientation);
        setId(id)
    }

    const freeState = () => {
        setImage('Default');
        setName('')
        setOrientation('')
        setImageModal(false);
    }

    const handleRidirect = (data, id) => {
        selectedItem(id);
        navigate(`../properties`);
    }

    return (
        <Box sx={{ width: { md: '80%', xs: '100%' }, marginX: 'auto', display: 'grid', gridTemplateColumns: { md: '0.3fr 0.7fr', xs: '1fr' }, position: 'relative', columnGap: '20px' }}>
            <Box sx={{ position: 'relative', width: '100%', marginTop: '80px', display: { md: 'block', xs: 'none' } }}>
                {
                    (image && !imageLoading)
                        ?
                        <Box sx={{ position: 'sticky', top: isSticky ? 1 / 2 : 0, left: 0, width: '100%', height: 400 }}>
                            <Box component={'img'} onLoad={() => setImageLoading(false)} src={`https://fundway.com/images/2024/${image}.png`} sx={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                            {(image !== 'Default' && !imageLoading) &&
                                <Box sx={{ color: '#11294D' }}>
                                    <Typography variant="subtitle1" sx={{ fontSize: '18px' }}>{name}</Typography>
                                    <Box component={'span'} sx={{ width: '90%', marginX: '0', height: '1px', backgroundColor: '#d4d4d4', display: 'block', marginY: '10px' }} />
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '90%', marginRight: 'auto' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Box component={'img'} src='https://fundway.com/images/icons/eye.svg' sx={{ width: { sm: '30px', xs: '20px' } }} />
                                            <Typography variant="subtitle1" marginLeft={'10px'} sx={{ fontSize: '18px' }}>{lng[language]['orientation']}</Typography>
                                        </Box>
                                        <Typography variant="subtitle1" sx={{ fontSize: '18px' }}>{orientation}</Typography>
                                    </Box>
                                </Box>
                            }
                        </Box>
                        :
                        <Box sx={{ position: 'sticky', top: isSticky ? 1 / 2 : 0, left: 0, width: '100%', height: 400 }}>
                            <Box component={'img'} onLoad={() => setImageLoading(false)} src={`https://fundway.com/images/2024/Default.png`} sx={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                        </Box>
                }

            </Box>

            <Box sx={{ width: '100%' }}>
                <Box ref={stickyRef} sx={{ position: isSticky ? 'sticky' : 'relative', top: 0, backgroundColor: '#fff' }}>
                    <Box sx={{ display: 'grid', marginX: 'auto', gridTemplateRows: '1fr', marginTop: { md: '50px', xs: '30px' } }}>
                        <Box component={motion.ul} className="container" variants={container} initial="hidden" animate="visible" sx={{ padding: 0 }}>
                            
                            <Box component={motion.li} className="item" variants={item} sx={{ display: { md: 'none', xs: 'grid' }, columnGap: '10px', rowGap: { sm: '30px', xs: '15px' }, gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr', justifyItems: 'center' }}>
                                {/* <Box component={'img'} src='https://fundway.com/images/2024/stair.svg' sx={{ width: { sm: '30px', xs: '15px' }, height: '100%', objectFit: 'contain' }} /> */}
                                <Box component={'img'} src='https://fundway.com/images/2024/double-bed.svg' sx={{ width: { sm: '30px', xs: '15px' }, height: '100%', objectFit: 'contain' }} />
                                <Box component={'img'} src='https://fundway.com/images/2024/terrace.svg' sx={{ width: { sm: '30px', xs: '15px' }, height: '100%', objectFit: 'contain' }} />
                                <Box component={'img'} src='https://fundway.com/images/2024/scalability.svg' sx={{ width: { sm: '30px', xs: '15px' }, height: '100%', objectFit: 'contain' }} />
                                <Box component={'img'} src='https://fundway.com/images/2024/parking.svg' sx={{ width: { sm: '25px', xs: '12.5px' }, height: '100%', objectFit: 'contain' }} />
                                <Box component={'img'} src='https://fundway.com/images/icons/ballot-check.svg' sx={{ width: { sm: '30px', xs: '15px' }, height: '100%', objectFit: 'contain' }} />
                                <Box component={'img'} src='https://fundway.com/images/2024/architect.svg' sx={{ width: { sm: '30px', xs: '15px' }, height: '100%', objectFit: 'contain' }} />
                            </Box>

                            <Box sx={{ display: { md: 'grid', xs: 'none' }, columnGap: '10px', rowGap: { sm: '30px', xs: '15px' }, gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr', justifyItems: 'center', marginTop: '10px' }}>
                                {/* <Typography sx={{ fontSize: { sm: '14px', xs: '14px' }, fontWeight: 500 }} color={'#11294D'} variant="body1">{lng[language]['floor'].toUpperCase()}</Typography> */}
                                <Typography sx={{ fontSize: { sm: '14px', xs: '14px' }, fontWeight: 500 }} color={'#707070'} variant="body1">{lng[language]['bedsTxt'].toUpperCase()}</Typography>
                                <Typography sx={{ fontSize: { sm: '14px', xs: '14px' }, fontWeight: 500 }} color={'#707070'} variant="body1">{lng[language]['terraceTxt'].toUpperCase()}</Typography>
                                <Typography sx={{ fontSize: { sm: '14px', xs: '14px', textAlign: 'center' }, fontWeight: 500 }} color={'#707070'} variant="body1">{lng[language]['areaTxt'].toUpperCase()}</Typography>
                                <Typography sx={{ fontSize: { sm: '14px', xs: '14px' }, fontWeight: 500 }} color={'#707070'} variant="body1">PARKING</Typography>
                                <Typography sx={{ fontSize: { sm: '14px', xs: '14px' }, fontWeight: 500 }} color={'#707070'} variant="body1">STATUS</Typography>
                                <Typography sx={{ fontSize: { sm: '14px', xs: '14px' }, fontWeight: 500 }} color={'#707070'} variant="body1">{lng[language]['plan'].toUpperCase()}</Typography>
                            </Box>
                            
                        </Box>
                    </Box>
                </Box>
                {
                    !dataSet.loading ?
                        <>
                            <Box>
                                <>
                                    {
                                        visibleAps.length !== 0 ?
                                            <Box>
                                                {
                                                    visibleAps.map((data, index) => (
                                                        <Box
                                                            onClick={() =>
                                                                // data.status === 'reserved' || 
                                                                data.status === 'free' ?
                                                                    !isMobile ?
                                                                        handleRidirect(data.typology, data.apartmentId)
                                                                        :
                                                                        handleImageModal(data.apartmentId, `${language === 'eng' ? data.apartmentName.replace('Apartamenti', 'Apartment') : data.apartmentName} ${language === 'eng' ? data.buildingName.replace('Godina', 'Building') : data.buildingName} ${data.objectName}`, data.orientationName, data.apartmentId)
                                                                    :
                                                                    ''
                                                            }
                                                            key={index}
                                                            sx={[{
                                                                backgroundColor: data?.sold ? '#FFDDDD' : '', cursor: 'pointer', width: { md: '100%', xs: '100%' }, display: 'grid', marginX: 'auto', gridGap: '20px', color: '#707070', alignItems: 'center', justifyItems: 'center', gridTemplateColumns: { sm: '1fr 1fr 1fr 1fr 1fr 1fr', xs: '1fr 1fr 1fr 1fr 1fr 1fr' }, rowGap: { sm: '30px', xs: '5px' }, borderTop: 'solid 1px #d4d4d4', borderBottom: dataSet?.data?.length === index + 1 ? 'solid 1px #707070' : '', transition: '.2s all',
                                                            },
                                                            data.status === 'free' ? { '&:hover': { backgroundColor: '#d2f8d2' } } : {},
                                                            data.status === 'sold' ? { '&:hover': { backgroundColor: '#FFF0F0' } } : {},
                                                            data.status === 'notForSale' ? { '&:hover': { backgroundColor: '#FFFDE8' } } : {},
                                                            data.status === 'reserved' ? { '&:hover': { backgroundColor: '#FFFDE8' } } : {},
                                                            data.status === 'salesAgreed' ? { '&:hover': { backgroundColor: '#E8ECFC' } } : {},
                                                            data.status === 'approved' ? { '&:hover': { backgroundColor: '#cecece' } } : {},
                                                            ]}
                                                            onMouseEnter={() => { setImage(data.apartmentId); setOrientation(lng[language][data.orientationName]); setName(`${language === 'eng' ? data.apartmentName.replace('Apartamenti', 'Apartment') : data.apartmentName} ${language === 'eng' ? data.buildingName.replace('Godina', 'Building') : data.buildingName} ${data.objectName}`) }}
                                                        >
                                                            {/* <Box sx={{ width: { sm: 1, xs: '47px' }, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <Typography textAlign={'center'} variant="subtitle1" sx={{ width: { sm: 1, xs: '47px' }, fontSize: { sm: '14px', xs: '11px' }, overflow: 'hidden', textOverflow: 'ellipsis' }}>+{data.floorNo}</Typography>
                                                            </Box> */}
                                                            <Box sx={{ width: { sm: 1, xs: '47px' }, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <Typography textAlign={'center'} variant="subtitle1" sx={{ width: { sm: 1, xs: '47px' }, fontSize: { sm: '13.5px', xs: '11px' }, overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.typology}</Typography>
                                                            </Box>

                                                            <Box sx={{ width: { sm: 1, xs: '47px' }, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <Typography textAlign={'center'} variant="subtitle1" sx={{ width: { sm: 1, xs: '47px' }, fontSize: { sm: '13.5px', xs: '11px' }, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                    {data.surfaceTerrace ? `${data.surfaceTerrace} M²` : '0 M²'}
                                                                </Typography>
                                                            </Box>
                                                            <Box sx={{ width: { sm: 1, xs: '47px' }, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <Typography textAlign={'center'} variant="subtitle1" sx={{ width: { sm: 1, xs: '47px' }, fontSize: { sm: '13.5px', xs: '11px' }, overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.surfaceGrossWithTerrace ? data.surfaceGrossWithTerrace : 0}M²</Typography>
                                                            </Box>
                                                            <Box sx={{ width: { sm: 1, xs: '47px' }, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <Typography textAlign={'center'} variant="subtitle1" sx={{ width: { sm: 1, xs: '47px' }, fontSize: { sm: '13.5px', xs: '11px' }, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                    {
                                                                        parseInt(data.apartmentId) === 1718
                                                                            ?
                                                                            '2 Parkings'
                                                                            :
                                                                            '1 Parking'
                                                                    }
                                                                </Typography>
                                                            </Box>

                                                            <Box sx={{ width: { sm: 1, xs: '47px' }, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <Typography textAlign={'center'} variant="subtitle1" sx={[{
                                                                    width: { sm: 1, xs: '47px' },
                                                                    fontSize: { sm: '13.5px', xs: language === 'alb' ? '7px' : '8px' },
                                                                    marginX: 'auto',
                                                                    overflow: 'unset',
                                                                    textOverflow: 'clip',
                                                                    padding: '10px 0',
                                                                    fontWeight: { md: 'inherit', xs: 'bold' }
                                                                },
                                                                    // data.status === 'free' ? {  color: {md : 'unset',xs: '#d2f8d2'}  } : {},
                                                                    // data.status === 'sold' ? {  color: {md : 'unset',xs:'#FFF0F0'}  } : {},
                                                                    // data.status === 'notForSale' ? {  color: {md : 'unset',xs:'#FFFDE8'}  } : {},
                                                                    // data.status === 'reserved' ? {  color: {md : 'unset',xs:'#FFFDE8'}  } : {},
                                                                    // data.status === 'salesAgreed' ? {  color    : {md : 'unset',xs:'#E8ECFC'}  } : {},

                                                                ]}>
                                                                    {
                                                                        data.status === 'free' && lng[language]['free'].toUpperCase()
                                                                    }
                                                                    {
                                                                        data.status === 'sold' && lng[language]['sold'].toUpperCase()
                                                                    }
                                                                    {
                                                                        data.status === 'notForSale' && lng[language]['reserved'].toUpperCase()
                                                                    }
                                                                    {
                                                                        data.status === 'reserved' && lng[language]['reserved'].toUpperCase()
                                                                    }
                                                                    {
                                                                        data.status === 'salesAgreed' && lng[language]['salesAgreed'].toUpperCase()
                                                                    }
                                                                    {
                                                                        data.status === 'approved' && lng[language]['approved'].toUpperCase()
                                                                    }
                                                                </Typography>
                                                            </Box>

                                                            {/* {
                                                                data.status === 'notForSale' ?
                                                                    <Box sx={{ width: { sm: 1, xs: '40px' }, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                                                        <Typography textAlign={'center'} variant="subtitle1" sx={{ width: { sm: 1, xs: '40px' }, fontSize: { sm: '14px', xs: '10px' }, marginX: 'auto', overflow: 'hidden', textOverflow: 'clip' }}></Typography>
                                                                        <Box sx={{ cursor: 'pointer', width: { sm: 1, xs: '30px' }, display: 'flex', justifyContent: 'center', alignItems: 'center' }} component={'a'} href={`https://vloramarina.fundway.com/${data.pdfFilePath}`} target='_blank'>
                                                                            <Box component={'img'} src='https://fundway.com/images/2024/download.svg' />
                                                                        </Box>
                                                                    </Box>
                                                                    :
                                                                    <Box sx={{ cursor: 'pointer', width: { sm: 1, xs: '40px' }, display: 'flex', justifyContent: 'center', alignItems: 'center' }} component={'a'} href={`https://vloramarina.fundway.com/${data.pdfFilePath}`} target='_blank'>
                                                                        <Box component={'img'} src='https://fundway.com/images/2024/download.svg' />
                                                                    </Box>
                                                            } */}
                                                            <Box sx={{ width: { sm: 1, xs: '47px' }, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <Typography textAlign={'center'} variant="subtitle1" sx={[{ width: { sm: 1, xs: '47px' }, fontSize: { sm: '13.5px', xs: '9px' }, marginX: 'auto', overflow: 'unset', textOverflow: 'clip', padding: '10px 0', color: '#333132', fontWeight: 800 }]}>
                                                                    {lng[language]['viewPlan'].toUpperCase()}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    ))
                                                }
                                            </Box>
                                            :
                                            <Typography sx={{ gridColumnStart: 1, gridColumnEnd: 7, fontWeight: 600 }} textAlign={'center'} variant='h4' color={'#11294D'}>NO DATA</Typography>
                                    }
                                </>

                                {
                                    otherLoader &&
                                    <Box sx={{ display: 'grid', gridTemplateRows: '1fr', rowGap: '5px' }}>
                                        {
                                            AvaLoader.map((data, index) => (
                                                <SkeletonComp
                                                    key={index}
                                                    height={data.heigh}
                                                    width={data.width}
                                                    variant={data.variant}
                                                />
                                            ))
                                        }
                                    </Box>
                                }
                                {
                                    (count > parseInt(visibleAps.length) || count === parseInt(visibleAps.length))  &&
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '40px' }}>
                                        {
                                            dataSet.loading || otherLoader ?
                                                <CircularProgress sx={{ width: '15px', height: '15px', color: '#002856' }} />
                                                :
                                                <Button variant='contained' onClick={() => loadMoreData(15)} sx={{ borderRadius: 0, background: '#002856', boxShadow: 'none', border: 'solid 1px #002856', '&:hover': { background: '#FFF', color: '#002856' } }}>
                                                    {lng[language]['loadMore']}
                                                </Button>
                                        }
                                    </Box>
                                } 
                                {
                                    imageModal && isMobile &&
                                    <ModalComp>
                                        <Box sx={{ background: '#FFF', display: 'flex', flexDirection: 'column', width: '80%', height: 'auto', alignItems: 'center', justifyItems: 'center' }}>
                                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '90%', marginX: 'auto', cursor: 'pointer', marginY: '20px' }} onClick={() => freeState()}>
                                                <CloseIcon />
                                            </Box>

                                            <Box marginBottom={'20px'} width={'90%'}>
                                                {(image !== 'Default' && !imageLoading) &&
                                                    <Box sx={{ color: '#11294D' }}>
                                                        <Typography variant="subtitle1" sx={{ fontSize: '18px' }}>{name}</Typography>
                                                        <Box component={'span'} sx={{ width: '100%', marginX: '0', height: '1px', backgroundColor: '#d4d4d4', display: 'block', marginY: '10px' }} />
                                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginRight: 'auto' }}>
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                <Box component={'img'} src='https://fundway.com/images/icons/eye.svg' sx={{ width: { sm: '30px', xs: '20px' } }} />
                                                                <Typography variant="subtitle1" marginLeft={'10px'} sx={{ fontSize: '18px' }}>{lng[language]['orientation']}</Typography>
                                                            </Box>
                                                            <Typography variant="subtitle1" sx={{ fontSize: '18px' }}>{orientation}</Typography>
                                                        </Box>
                                                    </Box>
                                                }
                                            </Box>
                                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginY: '20px' }}>
                                                <Box component={'img'} src={`https://fundway.com/images/2024/${image}.png`} sx={{ width: '90%', objectFit: 'contain' }} />

                                            </Box>
                                            <Box marginY={'20px'} sx={{ width: '90%' }}>
                                                <Button onClick={() => handleRidirect(data.typology, id)} sx={{ backgroundColor: '#11294D', color: '#FFF', borderRadius: 0, border: 'solid 1px #11294D', '&:hover': { backgroundColor: '#FFF', color: '#11294D' }, width: '100%' }}>
                                                    {lng[language]['viewPlan']}
                                                </Button>
                                            </Box>
                                        </Box>

                                    </ModalComp>
                                }

                            </Box>
                        </>
                        :
                        <Box sx={{
                            display: 'grid',
                            gridTemplateRows: '1fr',
                            rowGap: '5px'
                        }}>
                            {
                                AvaLoader.map((data, index) => (
                                    <SkeletonComp
                                        key={index}
                                        height={data.heigh}
                                        width={data.width}
                                        variant={data.variant}
                                    />
                                ))
                            }
                        </Box>
                }

            </Box>
        </Box>
    )
}